import { useEffect, memo, useRef } from "react"
import { $getRoot, $insertNodes } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $generateNodesFromDOM } from "@lexical/html"

export const HTMLParserPlugin = memo(({ initialHTML, updateOnChange = false }) => {
  const parsed = useRef(false)
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initialHTML && (!parsed.current || updateOnChange)) {
      editor.update(
        () => {
          parsed.current = true
          const dom = new DOMParser().parseFromString(initialHTML, "text/html")
          const nodes = $generateNodesFromDOM(editor, dom)
          const root = $getRoot()
          root.clear()
          root.selectStart()
          $insertNodes(nodes)
        },
        { tag: "history-merge" },
      )
    }
  }, [initialHTML, editor, updateOnChange])

  return null
})
